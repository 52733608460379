<template>
  <gql-table
    ref="discountCodesGqlTable"
    :fields="fields"
    :query="query"
    query-type="discountCodes"
    :actions="actions"
    title="Discount codes"
    @edit="onEdit"
    @remove="onRemove"
  >
    <template #cell(for_first_order)="data">
      <b-badge
        v-if="data.item.for_first_order"
        variant="light-success"
      >
        True
      </b-badge>
      <b-badge
        v-else
        variant="light-danger"
      >
        False
      </b-badge>
    </template>
  </gql-table>
</template>
<script>
import {
  BBadge, VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { getUserExtraTags } from '@/plugins/userUtils'
import { formatDateTime } from '@/plugins/formaters'
import DiscountAPI from '@/api/discount'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Remove',
    icon: 'Trash2Icon',
    emit: 'remove',
  },
]

const query = [
  'id',
  'code',
  'value_string',
  'max_count',
  'usage_count',
  'max_count_per_user',
  'for_first_order',
  'absolute_value',
  'relative_value',
  'value_string',
]
export default {
  components: {
    GqlTable,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    discountId: String,
  },
  data() {
    return {
      actions,
      query,
    }
  },
  computed: {
    fields() {
      return [
        {
          name: 'code', label: 'Custom code', filterable: 'like',
        },
        {
          name: 'value_string', label: 'Value',
        },
        {
          name: 'usage_count',
          label: 'Usage count',
          sortable: true,
        },
        {
          name: 'max_count',
          label: 'Max count',
          sortable: true,
        },
        {
          name: 'max_count_per_user',
          label: 'Max count per user',
          sortable: true,
        },
        {
          name: 'for_first_order', label: 'For first order',
        },
        {
          name: 'discount',
          label: 'discount',
          filterable: { type: 'eq', visible: false },
          default: this.discountId,
          visible: false,
        },
      ]
    },
  },
  methods: {
    getUserExtraTags,
    formatDateTime,
    onRemove(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Code will be removed.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!',
      }).then(result => {
        if (result.isConfirmed) {
          DiscountAPI.deleteCode(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Deleted',
                  icon: 'SendIcon',
                  text: 'Discount code was removed',
                  variant: 'success',
                },
              })
              this.$emit('reload')
            })
        }
      })
    },
    onEdit(item) {
      this.$emit('edit', item)
    },
  },
}
</script>
