<template>
  <b-overlay
    :show="isBusy"
    rounded="sm"
  >
    <b-form @submit="onSubmit">
      <b-form-row>
        <b-col
          md="6"
          xs="12"
        >
          <label>Emails</label>
          <b-form-textarea
            v-model="form.items"
            placeholder="Emails"
            rows="50"
          />
        </b-col>
        <b-col
          md="6"
          xs="12"
        >
          <b-form-group>
            <label>Max count</label>
            <b-form-input
              ref="max-count-users"
              v-model.number="form.max_count"
              type="number"
              min="0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr>
      <div class="d-flex justify-content-between">
        <b-button
          variant="primary"
          :disabled="form.items.length === 0"
          @click="onSubmit"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>
import {
  BButton, BOverlay, BForm, BFormRow, BCol, BFormTextarea, BFormInput, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BForm,
    BFormRow,
    BCol,
    BFormTextarea,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        items: '',
        max_count: 0,
      },
    }
  },
  methods: {
    onSubmit() {
      const items = this.form.items
        .replace(/ +/g, '')
        .replace(/\n/g, ',')
        .replace(/\r/g, ',')
        .replace(/\t/g, ',')
        .split(',')

      if (items.length < 1) return

      this.$emit('submit', {
        items: items.filter(item => (item.length > 0)), max_count: this.form.max_count,
      })
    },
  },
}
</script>
