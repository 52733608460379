<template>
  <div v-if="loaded">
    <b-row>
      <b-col
        xl="4"
        lg="6"
        md="8"
        sm="10"
      >
        <b-button-group
          :size="isMobile ? 'sm' : 'md'"
          class="btn-block my-1"
        >
          <b-button
            variant="primary"
            @click="onGenerateCodes"
          >
            Create new random codes
          </b-button>
          <b-button
            variant="secondary"
            @click="onCreateCustomDiscountCode"
          >
            Create custom code
          </b-button>
          <b-button
            v-b-modal.usersToDiscountModal
            variant="info"
          >
            Assign users to discount
          </b-button>
          <b-button
            v-b-modal.modal-product-to-discount
            variant="primary"
          >
            Assign product to discount
          </b-button>
        </b-button-group>
      </b-col>
      <b-col
        lg="4"
        md="4"
        sm="4"
      >
        <h2 class="ml-auto my-2">
          | {{ name }} {{ value_string }}
          <b-link
            v-if="partner"
            :to="{ name: 'partner-detail', params: { id: partner.id } }"
          >
            {{ partner.aff_custom_key }} - {{ partner.user.email }}
          </b-link>
        </h2>
      </b-col>
    </b-row>
    <app-collapse
      class="my-1"
    >
      <app-collapse-item
        title="Users"
      >
        <discount-to-user-table
          ref="discountUsersTable"
          :discount-id="discountId"
        />
      </app-collapse-item>
      <app-collapse-item
        v-if="products.length > 0"
        title="Products"
      >
        <products-table
          :discount-id="discountId"
        />
      </app-collapse-item>
    </app-collapse>

    <discount-codes-gql-table
      ref="discountCodesTable"
      :discount-id="discountId"
      class="mt-2"
      @edit="onEdit"
      @reload="onReloadDiscountCodes"
    />

    <b-modal
      id="modal-custom-code"
      ref="modal-custom-code"
      centered
      size="lg"
      :title="`${discountCodeActionTitle} custom code`"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <custom-discount-code-form
        :discount-id="discountId"
        :discount-code="discountCodeEdit"
        @close="onCloseCustomDiscount"
      />
    </b-modal>

    <product-to-discount-modal :discount-id="discountId" />

    <b-modal
      id="usersToDiscountModal"
      title="Assign users to discount by emails (separated by ',')"
      size="lg"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <users-to-discount-form
        :is-busy="discountToUserLoading"
        @submit="onDiscountToUserSubmit"
      />
    </b-modal>
  </div>
</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import Swal from 'sweetalert2'
import {
  BButton, BButtonGroup, BCol, BLink, BModal, BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import DiscountAPI from '@/api/discount'
import ProductsTable from './components/ProductsTable.vue'
import ProductToDiscountModal from './components/ProductToDiscountModal.vue'
import DiscountToUserTable from '@/components/entity/DiscountToUser/DiscountToUserTable.vue'
import UsersToDiscountForm from '@/views/discount/detail/components/UsersToDiscountForm.vue'
import DiscountCodesGqlTable from '@/views/discount/detail/components/DiscountCodesGqlTable.vue'
import CustomDiscountCodeForm from '@/views/discount/detail/components/CustomDiscountCodeForm.vue'

export default {
  components: {
    CustomDiscountCodeForm,
    DiscountCodesGqlTable,
    BLink,
    UsersToDiscountForm,
    BModal,
    DiscountToUserTable,
    ProductToDiscountModal,
    ProductsTable,
    BButton,
    BButtonGroup,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
  },
  data() {
    return {
      loaded: false,
      discountToUserLoading: false,
      reloadUsersTable: false,
      discountCodeEdit: null,
      discountCodeActionTitle: 'Create',
    }
  },
  computed: {
    discountId() {
      return this.$route.params.id
    },
    ...get('discountsList@row', {
      products: 'products', name: 'name', codes: 'codes', value_string: 'value_string', partner: 'partner',
    }),
    ...get('app', ['isMobile']),
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    onReloadDiscountCodes() {
      this.$refs.discountCodesTable.$refs.discountCodesGqlTable.reloadAll(true)
    },
    onCloseCustomDiscount() {
      this.$bvModal.hide('modal-custom-code')
      this.onReloadDiscountCodes()
    },
    onEdit(item) {
      this.discountCodeActionTitle = 'Update'
      this.discountCodeEdit = item
      this.$bvModal.show('modal-custom-code')
    },
    onCreateCustomDiscountCode() {
      this.discountCodeActionTitle = 'Create'
      this.discountCodeEdit = null
      this.$bvModal.show('modal-custom-code')
    },
    loadData() {
      dispatch('discountsList/get', this.discountId)
        .then(() => {
          this.loaded = true
        }).catch(err => {
          if (err.status === 404) {
            this.$router.push({ name: 'error-404' })
          }
        })
    },
    async onGenerateCodes() {
      const { value: formValues } = await Swal.fire({
        title: 'Create new discount codes',
        html:
            '<div>Count: <input id="swal-input1" class="swal2-input" type="number" min="0" max="100"></div>'
            + '<div>Max usage: <input id="swal-input2" class="swal2-input" type="number" value="0"></div>',
        focusConfirm: false,
        preConfirm: () => ({
          count: document.getElementById('swal-input1').value,
          maxCount: document.getElementById('swal-input2').value,
        }),
      })
      if (formValues) {
        DiscountAPI.postCreateCodes(this.discountId, formValues.count, formValues.maxCount).then(() => {
          this.loadData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Created',
              icon: 'SendIcon',
              text: `${formValues.count} code was created`,
              variant: 'success',
            },
          })
          this.$refs.discountCodesTable.$refs.discountCodesGqlTable.reloadAll(true)
        })
      }
    },
    onDiscountToUserSubmit(data) {
      this.discountToUserLoading = true
      DiscountAPI.assignUsersToDiscount(this.discountId, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Users was assigned to the discount',
              icon: 'SendIcon',
              variant: 'success',
            },
          })
          this.$bvModal.hide('usersToDiscountModal')
        }).catch(error => {
          if (error.status === 400) {
            Swal.fire({
              title: 'Some emails was not assign to discount',
              text: error.data.detail,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            })
          }
        })
        .finally(() => {
          this.$refs.discountUsersTable.reload()
          this.discountToUserLoading = false
        })
    },
  },
}
</script>
