<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-form
      v-if="!loading"
      ref="discount-form"
    >
      <b-form-group>
        <label>Discount code</label>
        <b-form-input
          ref="discount-code"
          v-model="form.code"
          type="text"
          placeholder="Discount code"
          @input="form.code = form.code.toUpperCase()"
        />
      </b-form-group>
      <b-form-row>
        <b-col
          md="8"
          xs="12"
        >
          <b-form-group>
            <label>Max count per user</label>
            <b-form-input
              ref="max-count"
              v-model.number="form.max_count_per_user"
              type="number"
              :disabled="form.code.length === 0"
              min="0"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xs="12"
        >
          <b-form-group>
            <label>Max count</label>
            <b-form-input
              ref="max-count"
              v-model.number="form.max_count"
              type="number"
              :disabled="form.code.length === 0"
              min="0"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col
          md="6"
          xs="12"
        >
          <label>Absolute value</label>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="DollarSignIcon" />
            </b-input-group-prepend>
            <b-form-input
              ref="absolute-value"
              v-model.number="form.absolute_value"
              type="number"
              :disabled="disableAbsoluteValue"
              min="0"
            />
          </b-input-group>
        </b-col>

        <b-col
          md="6"
          xs="12"
        >
          <label>Relative value</label>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="PercentIcon" />
            </b-input-group-prepend>
            <b-form-input
              ref="absolute-value"
              v-model.number="form.relative_value"
              type="number"
              :disabled="disableRelativeValue"
              min="0"
              max="100"
            />
          </b-input-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-form-group class="mt-1">
          <label>Only for first purchase</label>
          <b-form-checkbox
            ref="max-count"
            v-model="form.for_first_order"
          />
        </b-form-group>
      </b-form-row>
      <div class="d-flex justify-content-between align-items-center">
        <b-button
          variant="primary"
          @click="onSubmit"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </b-overlay>
</template>
<script>

import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRow,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch } from 'vuex-pathify'
import DiscountAPI from '@/api/discount'

export default {
  components: {
    BButton,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BForm,
    BInputGroupPrepend,
    BFormInput,
    BOverlay,
    BFormRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    actionTitle: {
      type: String,
      default: 'Create',
    },
    discountId: String,
    discountCode: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        code: this.discountCode ? this.discountCode.code : '',
        max_count: this.discountCode ? this.discountCode.max_count : 0,
        relative_value: this.discountCode ? this.discountCode.relative_value : null,
        absolute_value: this.discountCode ? this.discountCode.absolute_value : null,
        max_count_per_user: this.discountCode ? this.discountCode.max_count_per_user : 0,
        for_first_order: this.discountCode ? this.discountCode.for_first_order : false,
      },
    }
  },
  computed: {
    disableAbsoluteValue() {
      return this.form.relative_value > 0
    },
    disableRelativeValue() {
      return this.form.absolute_value > 0
    },
  },
  methods: {
    onSubmit() {
      this.loading = true

      if (this.discountCode) {
        DiscountAPI.patchDiscountCode(this.discountCode.id, this.form).then(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'SendIcon',
              text: 'Code was updated',
              variant: 'success',
            },
          })
          this.$emit('close')
        })
      } else {
        this.form.discount = this.discountId
        dispatch('discountsList/update', { id: this.discountId, data: { codes: [this.form] } })
          .then(() => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Created',
                icon: 'SendIcon',
                text: 'Code was created',
                variant: 'success',
              },
            })
            this.$emit('close')
          })
      }
    },
  },
}
</script>
