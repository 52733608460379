<template>
  <b-modal
    id="modal-user-to-discount-edit"
    ref="modal-user-to-discount-edit"
    cancel-variant="outline-secondary"
    ok-title="Save"
    cancel-title="Close"
    centered
    size="lg"
    :title="`Edit user: ${user}`"
    :no-close-on-backdrop="true"
    @ok="handleOk"
  >

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-form>
        <label>Usage count</label>
        <b-form-input
          v-model.number="form.usage_count"
          type="number"
          min="0"
        />
        <label>Max count</label>
        <b-form-input
          v-model.number="form.max_count"
          type="number"
          min="0"
        />
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, VBModal, BOverlay, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DiscountAPI from '@/api/discount'

export default {
  components: {
    BModal,
    BForm,
    BOverlay,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    discountId: String,
    discountToUser: Object,
  },
  data() {
    return {
      loading: false,
      user: '',
      form: {
        usage_count: this.discountToUser ? this.discountToUser.usage_count : 0,
        max_count: this.discountToUser ? this.discountToUser.max_count : 0,
      },
    }
  },
  watch: {
    discountToUser() {
      this.form.usage_count = this.discountToUser ? this.discountToUser.usage_count : 0
      this.form.max_count = this.discountToUser ? this.discountToUser.max_count : 0
      this.user = this.discountToUser ? this.discountToUser.user.email : ''
    },
  },
  methods: {
    handleOk(event) {
      // prevent modal before immediate closing
      event.preventDefault()

      this.loading = true

      DiscountAPI.patchDiscountToUser(this.discountId, this.discountToUser.user.id, this.form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'Edit3Icon',
              text: 'User to discount was update',
              variant: 'success',
            },
          })
          this.$refs['modal-user-to-discount-edit'].hide()
          this.$emit('close')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'Edit3Icon',
              text: 'Edit failed!',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

}
</script>
