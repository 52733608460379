<template>
  <b-modal
    id="modal-product-to-discount"
    ref="modal-product-to-discount"
    cancel-variant="outline-secondary"
    ok-title="Create"
    cancel-title="Close"
    centered
    size="lg"
    title="Assign products to discount"
    :no-close-on-backdrop="true"
    @ok="handleOk"
  >

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-form
        v-if="!loading"
        ref="discount-form"
      >
        <label>Products</label>
        <product-tree-select
          :products-list="products.map(item => item.product)"
          @selected="value => productIds = value"
        />
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BModal, BForm, VBModal, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch, get } from 'vuex-pathify'
import { formatCurrency } from '@/plugins/formaters'
import ProductTreeSelect from '@/components/ui/forms/ProductTreeSelect.vue'

export default {
  components: {
    ProductTreeSelect,
    BModal,
    BForm,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    discountId: String,
  },
  data() {
    return {
      loading: false,
      productIds: [],
    }
  },
  computed: {
    ...get('discountsList@row', { products: 'products' }),
  },
  methods: {
    formatCurrency,
    handleOk(event) {
      // prevent modal before immediate closing
      event.preventDefault()
      this.loading = true
      const products = []
      this.productIds.forEach(id => {
        products.push({ product: id, discount: this.discountId })
      })

      dispatch('discountsList/update', { id: this.discountId, data: { products } })
        .then(() => {
          dispatch('discountsList/get', this.discountId)
          this.loading = false
          this.$refs['modal-product-to-discount'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated',
              icon: 'SendIcon',
              text: 'Products was assign',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
