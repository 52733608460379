<template>
  <treeselect
    v-model="products"
    :multiple="true"
    value-consists-of="LEAF_PRIORITY"
    :options="productsTree"
    @input="$emit('selected', products)"
  />
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import OrdersAPI from '@/api/order'

export default {
  components: {
    Treeselect,
  },
  props: {
    productsList: Array,
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      loading: true,
      products: [],
    }
  },
  computed: {
    productsTree() {
      const tree = []
      this.data.forEach(product => {
        const productTypeIndex = tree.findIndex(item => item.id === product.account_type)
        if (productTypeIndex === -1) {
          tree.push({
            id: product.account_type,
            label: product.account_type.toUpperCase(),
            children: [
              {
                id: `${product.platform}${product.account_type}`,
                label: product.platform,
                children: [{
                  id: product.id,
                  label: product.name,
                }],
              },
            ],
          })
        } else {
          const platformIndex = tree[productTypeIndex].children.findIndex(platform => platform.label === product.platform)
          if (platformIndex === -1) {
            tree[productTypeIndex].children.push({
              id: `${product.platform}${product.account_type}`,
              label: product.platform,
              children: [{
                id: product.id,
                label: product.name,
              }],
            })
          } else {
            tree[productTypeIndex].children[platformIndex].children.push({
              id: product.id,
              label: product.name,
            })
          }
        }
      })
      return tree
    },
  },
  mounted() {
    OrdersAPI.fetchAllProducts().then(response => {
      this.data = response
      this.loading = false
      this.products = this.productsList.map(product => product.id)
    })
  },
  methods: {
    onInput(id) {
      this.$emit('input', id)
      const product = this.data.find(p => p.id === id)
      this.$emit('selected', product)
    },
  },
}
</script>
