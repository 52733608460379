<template>
  <div>
    <gql-table
      ref="discountToUserGqlTable"
      :fields="columns"
      :query="query"
      query-type="discountUsers"
      sort-by="usage_count"
      :sort-desc="true"
      :actions="actions"
      @remove="onRemove"
      @edit="editDiscountToUser"
    >
      <template #cell(user)="data">
        <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
          {{ data.item.user.firstName }} {{ data.item.user.lastName }}
          <br>
          <small>{{ data.item.user.email }}</small>
        </b-link>
      </template>

    </gql-table>

    <discount-to-user-edit-modal
      ref="editForm"
      :discount-id="discountId"
      :discount-to-user="discountToUserEdit"
      @close="onEditClose"
    />
  </div>
</template>
<script>
import { BLink } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import DiscountToUserEditModal from '@/views/discount/detail/components/DiscountToUserEditModal.vue'
import DiscountApi from '@/api/discount'

const query = [
  'id',
  'max_count',
  'usage_count',
  {
    user: {
      fields: [
        'id', 'firstName', 'lastName', 'email',
      ],
    },
  },
  {
    discount: {
      fields: [
        'id',
      ],
    },
  },
]
const actions = [
  {
    text: 'Remove',
    icon: 'Trash2Icon',
    emit: 'remove',
  },
  {
    text: 'Edit',
    icon: 'Edit3Icon',
    emit: 'edit',
  },
]

export default {
  components: {
    DiscountToUserEditModal,
    BLink,
    GqlTable,
  },
  props: {
    discountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actions,
      query,
      discountToUserEdit: null,
      columns: [
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email',
          },
        },
        {
          name: 'discount',
          label: 'Discount',
          filterable: {
            type: 'eq',
            visible: false,
          },
          default: this.discountId,
          visible: false,
        },
        {
          name: 'max_count',
          label: 'Max count',
        },
        {
          name: 'usage_count',
          label: 'Usage count',
        },
      ],
    }
  },
  methods: {
    editDiscountToUser(item) {
      this.discountToUserEdit = item
      this.$refs.editForm.$refs['modal-user-to-discount-edit'].show()
    },
    onEditClose() {
      this.$refs.discountToUserGqlTable.reloadAll(true)
    },
    onRemove(item) {
      Swal.fire({
        title: 'Remove this user from discount',
        text: 'Are you sure you want to remove this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          DiscountApi.deleteDiscountToUser(item.discount.id, item.user.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Remove successful',
                  icon: 'TrashIcon',
                  text: 'User was successfully removed from discount',
                  variant: 'success',
                },
              })
              this.$refs.discountToUserGqlTable.reloadAll(true)
            })
        }
      })
    },
    reload() {
      this.$refs.discountToUserGqlTable.reloadAll(true)
    },
  },
}
</script>
