<template>
  <div>
    <b-table
      :items="products"
      :fields="fields"
      striped
      responsive
      small
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(name)="data">
        <span class="font-weight-bold">{{ data.item.product.platform }}</span> | {{ data.item.product.name }}
      </template>

      <template #cell(price)="data">
        {{ formatCurrency(data.item.product.price, 'USD') }}
      </template>

      <template #cell(actions)="data">
        <action-list
          :items="actions"
          @remove="onRemove(data.item.product.id)"
        />
      </template>
    </b-table>
    <pagination-row />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch, get } from 'vuex-pathify'
import ActionList from '@/components/ui/ActionList.vue'
import PaginationRow from '@/components/ui/tables/PaginationRow.vue'
import table from '@/mixins/table'
import { formatCurrency } from '@/plugins/formaters'

const actions = [
  {
    text: 'Remove',
    icon: 'Trash2Icon',
    emit: 'remove',
  },
]

export default {
  components: {
    PaginationRow,
    BTable,
    ActionList,
  },
  mixins: [table],
  props: {
    discountId: String,
  },
  computed: {
    ...get('discountsList@row', { products: 'products' }),
  },
  data() {
    return {
      loading: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'price',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      actions,
    }
  },
  watch: {
    products() {
      this.totalRows = this.products.length
    },
  },
  created() {
    this.totalRows = this.products.length
  },
  methods: {
    formatCurrency,
    onRemove(id) {
      const productsList = this.products.filter(product => product.product.id !== id)

      dispatch('discountsList/update', { id: this.discountId, data: { products: productsList } })
        .then(() => {
          dispatch('discountsList/get', this.discountId)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted',
              icon: 'SendIcon',
              text: 'Product was removed form discount',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>
